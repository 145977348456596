<div *ngFor="let data of plantData" class="plant-main-container">
  <div class="flex heading-content">
    <p class="heading mb-1">{{ data.name }}</p>
    <button class="actions-button" (click)="onActionsClick($event, data)">
      <i class="pi pi-ellipsis-h"></i>
    </button>
  </div>
  <span
    [ngClass]="{
      'status-active': data.isActive === true,
      'status-inactive': data.isActive === false
    }"
  >
    {{ data.isActive === true ? "Active" : "Inactive" }}
  </span>
  <div class="mt-[10px]">
    <p class="location mb-[10px]">
      <img src="/assets/images/location.svg" class="location-img" />
      {{ data.address }}
    </p>
    <p class="location">
      <img src="/assets/images/routing-2.svg" class="location-img" />{{
        data.stateName
      }}
    </p>
  </div>
  <p-contextMenu #contextMenu [model]="contextMenu.model"></p-contextMenu>
</div>
