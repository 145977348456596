<form [formGroup]="plantForm">
  <div class="main-plan-container">
    <div class="flex heading-container">
      <h3 class="heading">
        {{
          (!isEditMode ? "ADD_PLANT_CONTENT.FIRST" : "ADD_PLANT_CONTENT.SECOND")
            | translate
        }}{{ isEditMode ? plantName : "" }}
      </h3>
      <p class="content">{{ "ADD_PLANT_CONTENT.THIRD" | translate }}</p>
    </div>

    <label for="plantName" class="label-txt"
      >{{ "ADD_PLANT_CONTENT.FOURTH" | translate }}
      <span class="col-red">*</span>
    </label>
    <input
      id="plantName"
      type="text"
      pInputText
      formControlName="plantName"
      placeholder="{{ 'ADD_PLANT_CONTENT.FIFTH' | translate }}"
    />
    <div *ngIf="isSubmitted">
      <div [ngSwitch]="getPlantError('plantName')">
        <div *ngSwitchCase="'required'" class="error">
          Plant Name is required.
        </div>
        <div *ngSwitchCase="'whitespace'" class="error">
          Plant Name cannot be empty.
        </div>
        <div *ngSwitchCase="'alphanumeric'" class="error">
          Plant Name must be alphanumeric.
        </div>
        <div *ngSwitchCase="'maxLength'" class="error">
          Plant Name must be up to 50 characters.
        </div>
      </div>
    </div>

    <label for="plantAddress" class="label-txt"
      >{{ "ADD_PLANT_CONTENT.SIXTH" | translate }}
      <span class="col-red">*</span>
    </label>
    <input
      id="plantAddress"
      type="text"
      pInputText
      formControlName="plantAddress"
      placeholder="{{ 'Enter Plant Address' | translate }}"
    />
    <div *ngIf="isSubmitted">
      <div [ngSwitch]="getPlantError('plantAddress')">
        <div *ngSwitchCase="'required'" class="error">
          Plant Address is required.
        </div>
        <div *ngSwitchCase="'whitespace'" class="error">
          Plant Address cannot be empty.
        </div>
        <div *ngSwitchCase="'maxLength'" class="error">
          Plant Address must be up to 150 characters.
        </div>
      </div>
    </div>

    <label for="plantState" class="label-txt"
      >{{ "ADD_PLANT_CONTENT.EIGHT" | translate }}
      <span class="col-red">*</span>
    </label>
    <p-dropdown
      [options]="states"
      formControlName="plantState"
      optionLabel="name"
      placeholder="{{ 'ADD_PLANT_CONTENT.NIGHT' | translate }}"
      class="block w-full"
      (onChange)="onStateChange($event)"
    >
    </p-dropdown>
    <div
      *ngIf="plantForm.get('plantState')?.hasError('required') && isSubmitted"
      class="error"
    >
      State is required.
    </div>

    <label for="plantCity" class="label-txt"
      >{{ "ADD_PLANT_CONTENT.TENTH" | translate }}
      <span class="col-red">*</span>
    </label>
    <p-dropdown
      [options]="cities"
      formControlName="plantCity"
      optionLabel="name"
      placeholder="{{ 'ADD_PLANT_CONTENT.ELEVENTH' | translate }}"
      class="block w-full"
      (onChange)="onCityChange($event)"
    >
    </p-dropdown>
    <div
      *ngIf="plantForm.get('plantCity')?.hasError('required') && isSubmitted"
      class="error"
    >
      City is required.
    </div>

    <label for="factoryName" class="label-txt"
      >{{ "ADD_PLANT_CONTENT.TWELVE" | translate }}
      <span class="col-red">*</span>
    </label>
    <div class="flex justify-between">
      <div class="w-full flex items-center gap-5">
        <input
          id="factoryInput"
          type="text"
          pInputText
          formControlName="factoryName"
          placeholder="{{ 'ADD_PLANT_CONTENT.THIRTEEN' | translate }}"
          class="flex-grow mr-2"
        />
        <app-button
          [name]="'SAVE'"
          class="h-full mt-3"
          (onClick)="addFactory()"
          [btnClass]="'save-factory-btn'"
          [disabled]="!canAddFactory()"
        ></app-button>
      </div>
    </div>
    <div *ngIf="displayFactoryNameErrors">
      <div [ngSwitch]="getFirstError('factoryName')">
        <div *ngSwitchCase="'alphanumeric'" class="error">
          Factory Name must be alphanumeric.
        </div>
        <div *ngSwitchCase="'maxLength'" class="error">
          Factory Name must be up to 50 characters.
        </div>
      </div>
    </div>
    <div *ngIf="isSubmitted && !hasValidFactories()" class="error">
      Factory is required.
    </div>

    <!-- <div *ngIf="displayFactoryNameErrors">
      <div class="error" *ngIf="getFirstError('factoryName') as error">
        <div *ngIf="error === 'required'">Factory is required.</div>
        <div *ngIf="error === 'alphanumeric'">
          Factory Name must be alphanumeric.
        </div>
        <div *ngIf="error === 'maxLength'">
          Factory Name must be up to 50 characters.
        </div>
      </div>
    </div> -->

    <div *ngIf="factoryNames.length > 0" class="flex flex-wrap">
      <ng-container *ngFor="let factory of factoryNames">
        <div *ngIf="!factory.isDeleted" class="chip-container mt-2 mr-2">
          <div class="chip">
            {{ factory?.factoryName }}
            <span class="delete-icon ml-2" (click)="removeFactory(factory)"
              >&times;</span
            >
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex justify-between mt-6">
      <div class="w-1/2 pr-2">
        <app-button
          [name]="'CANCEL'"
          (onClick)="onCancel()"
          class="w-full"
          [btnClass]="'cancel-btn'"
        ></app-button>
      </div>
      <div class="w-1/2 pl-2">
        <app-button
          [name]="!isEditMode ? 'REGISTER' : 'SAVE_CHANGES'"
          class="w-full"
          [btnClass]="'save-btn'"
          (onClick)="onSubmit()"
        ></app-button>
      </div>
    </div>
  </div>
</form>
