<div class="main-plan-container" [formGroup]="locationForm">
  <div class="flex heading-container">
    <!-- <img src="assets/images/marker-img.png" /> -->
    <h3 class="heading">{{ "Select Lines" }}</h3>
  </div>
  <label for="plant" class="label-txt"
    >{{ "Plant" | translate }} <span class="col-red">*</span>
  </label>
  <p-dropdown
    [options]="plantData"
    formControlName="plant"
    optionLabel="name"
    placeholder="{{ 'Select Plant' | translate }}"
    class="block w-full"
    (onChange)="onPlantChange($event)"
    [showClear]="false"
  ></p-dropdown>
  <div
    *ngIf="locationForm.get('plant')?.hasError('required') && isSubmitted"
    class="error"
  >
    Plant is required.
  </div>
  <label for="factory" class="label-txt">{{ "FACTORY" | translate }} </label>
  <p-dropdown
    [options]="factoryData"
    formControlName="factory"
    optionLabel="factoryName"
    placeholder="{{ 'SELECT_FACTORY' | translate }}"
    class="block w-full"
    (onChange)="onFactoryChange($event)"
    [showClear]="true"
  >
  </p-dropdown>
  <label for="line" class="label-txt">{{ "LINE" | translate }}</label>
  <div class="multiselect-dropdown">
    <p-multiSelect
    [options]="filteredLines"
    formControlName="line"
    [(ngModel)]="selectedLines"
    optionLabel="lineName"
    placeholder="Select Lines"
    (onChange)="onLineSelectionChange($event)"
  ></p-multiSelect>
  </div>
  <div class="flex justify-between mt-6">
    <div class="w-1/2 pr-2">
      <app-button
        [name]="'VIEW_ALL_LINES'"
        (onClick)="onViewLines()"
        class="w-full"
        [btnClass]="'cancel-btn'"
      ></app-button>
    </div>
    <div class="w-1/2 pl-2">
      <app-button
        [name]="'SAVE_&_VIEW'"
        (onClick)="onViewLines()"
        class="w-full"
        [btnClass]="'save-btn'"
        [disabled]="isSaveViewDisabled"
      ></app-button>
    </div>
  </div>
</div>
