import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { API_END_POINTS } from '../../../constants/constant';
import { City } from '../../../interfaces/city.interface';
import { PlantData } from '../../../interfaces/plant.interface';
import { State } from '../../../interfaces/state.interface';
import { HttpService } from '../../../services/http.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

interface FormPlantData {
  name: string;
  stateId: number;
  cityId: number;
  address: string;
  factories?: {
    id?: number;
    factoryName: string;
    isNew?: boolean;
    isDeleted?: boolean;
  }[];
  factoryName?: string[];
  factoryId?: number[];
}

@Component({
  selector: 'app-add-plant-modal',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ButtonComponent,
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    TranslateModule,
  ],
  templateUrl: './add-plant-modal.component.html',
  styleUrl: './add-plant-modal.component.scss',
})
export class AddPlantModalComponent implements OnInit {
  plantForm!: FormGroup;
  factoryNames: {
    id?: number;
    factoryName: string;
    isNew?: boolean;
    isDeleted?: boolean;
  }[] = [];
  plantName: string = '';
  isEditMode: boolean = false;
  isMobileView: boolean = false;
  isSubmitted: boolean = false;
  displayFactoryNameErrors: boolean = false;
  states!: State[];
  cities!: City[];
  confirmationRef: DynamicDialogRef | undefined;

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private httpService: HttpService
  ) {
    this.checkScreenSize();
    this.createForm();
  }

  ngOnInit() {
    if (this.config.data) {
      this.isEditMode = this.config.data.action === 'edit';
      this.plantForm.patchValue(this.config.data.plantData);
      this.getStateData(() => {
        this.selectStateAndCity(this.config.data.plantData);
      });
    } else {
      this.getStateData();
    }
  }

  getFirstError(controlName: string): string | null {
    const control = this.plantForm.get(controlName);
    if (control?.hasError('required')) {
      return 'required';
    } else if (control?.hasError('whitespace')) {
      return 'whitespace';
    } else if (control?.hasError('alphanumeric')) {
      return 'alphanumeric';
    } else if (control?.hasError('maxlength')) {
      return 'maxLength';
    } else if (control?.hasError('maxLength')) {
      return 'maxLength';
    }
    return null;
  }

  getPlantError(controlName: string): string | null {
    return this.getFirstError(controlName);
  }

  static notOnlyWhitespace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  selectStateAndCity(editData: PlantData) {
    const stateToSelect = this.states?.find(
      (state) => state.id === editData?.stateId
    );
    this.plantForm.patchValue({
      plantState: stateToSelect ? stateToSelect : null,
    });
    if (stateToSelect) {
      this.getCityData(stateToSelect.id, () => {
        const cityToSelect = this.cities.find(
          (city) => city.id === editData.cityId
        );
        this.plantForm.patchValue({
          plantCity: cityToSelect ? cityToSelect : null,
        });
      });
    }
  }

  getStateData(callback?: () => void) {
    const url = API_END_POINTS.GET_STATE;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.states = response.data;
          if (callback) {
            callback();
          }
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onStateChange(event: { value: State }) {
    const selectedState = event.value;
    if (selectedState) {
      this.plantForm.get('plantCity')?.setValue(null);
      this.plantForm.get('plantCity')?.enable();
      this.getCityData(selectedState.id);
    } else {
      this.cities = [];
      this.plantForm.get('plantCity')?.setValue(null);
      this.plantForm.get('plantCity')?.disable();
    }
  }

  onCityChange(event: { value: City }) {
    const selectedState = event.value;
  }

  getCityData(selectedState: number, callback?: () => void) {
    const url = `${API_END_POINTS.GET_PLANT}/${selectedState}/City`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.cities = response.data;
          if (callback) {
            callback();
          }
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  createForm() {
    const editData = this.config.data?.plantData;
    this.plantName = editData?.name;
    this.plantForm = this.fb.group({
      plantName: [
        editData ? editData?.name : '',
        [
          Validators.required,
          AddPlantModalComponent.notOnlyWhitespace(),
          this.alphanumericWithSpacesValidator(50),
        ],
      ],
      plantAddress: [
        editData ? editData?.address : '',
        [
          Validators.required,
          AddPlantModalComponent.notOnlyWhitespace(),
          Validators.maxLength(150),
        ],
      ],
      plantState: [editData ? editData?.stateName : null, Validators.required],
      plantCity: [editData ? editData?.cityName : null, Validators.required],
      factoryName: ['', [this.alphanumericWithSpacesValidator(50)]],
    });
    if (editData?.factories && editData?.factories.length > 0) {
      this.factoryNames =
        editData?.factories?.map(
          (factory: { id: number; factoryName: string }) => ({
            id: factory.id,
            factoryName: factory.factoryName,
            isNew: false,
            isDeleted: false,
          })
        ) || [];
    }
    if (!editData) {
      this.plantForm.get('plantCity')?.disable();
    }
  }

  onCancel() {
    this.ref.close();
  }

  addFactory() {
    const factoryNameControl = this.plantForm.get('factoryName');
    if (factoryNameControl?.invalid) {
      this.displayFactoryNameErrors = true;
      return;
    }

    const factoryName = factoryNameControl?.value.trim();
    if (factoryName !== '') {
      this.factoryNames.push({ factoryName, isNew: true });
      factoryNameControl?.setValue('');
      this.displayFactoryNameErrors = false;
    }
  }

  removeFactory(factory: {
    id?: number;
    factoryName: string;
    isNew?: boolean;
    isDeleted?: boolean;
  }) {
    if (factory.isNew) {
      this.factoryNames = this.factoryNames.filter((f) => f !== factory);
    } else {
      this.openConfirmationModal(factory);
    }
  }

  confirmDeleteFactory(factory: {
    id?: number;
    factoryName: string;
    isNew?: boolean;
    isDeleted?: boolean;
  }) {
    this.factoryNames = this.factoryNames.map((f) => {
      if (f === factory) {
        return { ...f, isDeleted: true };
      }
      return f;
    });
  }

  openConfirmationModal(factory: {
    id?: number;
    factoryName: string;
    isNew?: boolean;
    isDeleted?: boolean;
  }) {
    this.confirmationRef = this.dialogService.open(ConfirmationModalComponent, {
      data: {
        headingContent: `${'Are you sure you want to delete '}${
          factory.factoryName
        }`,
        factoryContent:
          'Please note that this action will also remove associated data.',
        imageUrl: '/assets/images/archive-plant-img.svg',
      },
      width: '434px',
      dismissableMask: true,
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });

    this.confirmationRef.onClose.subscribe((result) => {
      if (result === 'confirmed') {
        this.confirmDeleteFactory(factory);
      }
    });
  }

  canAddFactory(): boolean {
    const factoryNameValue = this.plantForm.get('factoryName')?.value || '';
    return factoryNameValue.trim().length > 0;
  }

  hasValidFactories(): boolean {
    return this.factoryNames.some((factory) => !factory.isDeleted);
  }

  onSubmit() {
    this.isSubmitted = true;
    const hasValidFactories = this.hasValidFactories();
    if (this.plantForm.valid && hasValidFactories) {
      const formValues = this.plantForm.value;
      const baseBody: FormPlantData = {
        name: formValues.plantName,
        stateId: formValues.plantState.id,
        cityId: formValues.plantCity.id,
        address: formValues.plantAddress,
      };
      if (this.isEditMode) {
        const newFactoryNames = this.factoryNames
          .filter((f) => f.isNew)
          .map((f) => f.factoryName);
        const factoryIdsToDelete = this.factoryNames
          .filter((f) => f.isDeleted && f.id !== undefined)
          .map((f) => f.id as number);

        const editBody: FormPlantData = {
          ...baseBody,
          ...(newFactoryNames.length > 0 && { factoryName: newFactoryNames }),
          ...(factoryIdsToDelete.length > 0 && {
            factoryId: factoryIdsToDelete,
          }),
        };
        this.editPlant(editBody);
      } else {
        const factoryName = this.factoryNames.map((f) => f.factoryName);
        const addBody = {
          ...baseBody,
          ...(factoryName.length > 0 && { factoryName }),
        };

        this.addPlant(addBody);
      }
    }
  }

  addPlant(body: any) {
    const url = API_END_POINTS.POST_PLANT;
    this.httpService
      .postRequest(url, body)
      .then((response) => {
        if (response) {
          let contentTxt = this.translateService.instant('ADD_SUCCESS_MESSAGE');
          this.openModal(contentTxt, 'New Plant');
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  editPlant(body: any) {
    this.confirmationRef = this.dialogService.open(ConfirmationModalComponent, {
      data: {
        headingContent: 'Are you sure you want to save the changes',
        imageUrl: '/assets/images/restore-plant-img.svg',
      },
      width: '434px',
      dismissableMask: true,
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });

    this.confirmationRef.onClose.subscribe((result) => {
      if (result === 'confirmed') {
        const url = `${API_END_POINTS.UPDATE_PLANT}${this.config.data.plantData.id}`;
        this.httpService
          .putRequest(url, body)
          .then((response) => {
            if (response) {
              let contentTxt = this.translateService.instant(
                'UPDATE_SUCCESS_MESSAGE'
              );
              this.openModal(contentTxt, this.plantName);
            } else {
              console.error(response);
            }
          })
          .catch((error) => {
            console.error('There was an error:', error);
          });
      }
    });
  }

  openModal(headingContent: string, contentName: string) {
    let contentTxt = this.translateService.instant(headingContent);
    this.ref.close({
      action: this.isEditMode ? 'edit' : 'add',
      data: this.plantForm.value,
    });
    this.ref = this.dialogService.open(SuccessModalComponent, {
      data: { headingContent: contentTxt, contentName: contentName },
      width: '384px',
      dismissableMask: true,
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
  }

  alphanumericWithSpacesValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const errors: ValidationErrors = {};
      if (!/^[a-zA-Z0-9 ]*$/.test(value)) {
        errors['alphanumeric'] = true;
      }
      if (value.length > maxLength) {
        errors['maxLength'] = true;
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  private checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
  }

  @HostListener('window:resize')
  onResize() {
    this.checkScreenSize();
  }
}
