import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import { fromLonLat, toLonLat, transformExtent } from 'ol/proj';
import { Vector as VectorSource, XYZ } from 'ol/source';
import { Icon, Style } from 'ol/style';
import View from 'ol/View';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectLocationModalComponent } from '../../features/modals/select-location-modal/select-location-modal.component';
import { PlantData } from '../../interfaces/plant.interface';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  providers: [DynamicDialogRef],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss',
})
export class MapComponent {
  map!: Map;
  tooltip!: Overlay;
  isMobileView: boolean = false;
  @Input() plantData!: PlantData[];
  plantGroupedByCoordinates: { [key: string]: PlantData[] } = {};

  constructor(
    public ref: DynamicDialogRef,
    private dialogService: DialogService
  ) {
    this.checkScreenSize();
  }

  initializeMap(): void {
    const indiaExtent = transformExtent(
      [38.872, 6.4626999, 130.0, 37.1],
      'EPSG:4326',
      'EPSG:3857'
    );
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZ({
            attributions: '',
            url: 'https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
          }),
        }),
        new VectorLayer({
          source: new VectorSource(),
          style: new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: 'assets/images/location-marker.svg',
              scale: 0.8,
            }),
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([78.9629, 22.5937]),
        zoom: 0,
        minZoom: 0,
        extent: indiaExtent,
      }),
    });

    this.map.once('postrender', () => {
      this.map.getViewport().addEventListener('click', (event: MouseEvent) => {
        const pixel = this.map.getEventPixel(event);
        const coordinate = this.map.getCoordinateFromPixel(pixel);
        const features = this.map.getFeaturesAtPixel(pixel);
        if (features.length > 0) {
          const clickedFeature = features[0];
          const featureName = clickedFeature.get('name');
          const plantId = clickedFeature.get('id');
          const cityId = clickedFeature.get('cityId');
          this.dialogService.open(SelectLocationModalComponent, {
            data: {
              name: featureName,
              plantId,
              cityId,
            },
            width: '520px',
            styleClass: this.isMobileView
              ? 'custom-modal-border-radius mobile-modal'
              : 'custom-modal-border-radius',
          });
        } else {
          console.log('No features found at clicked position.');
        }
      });
    });
  }

  ngOnInit() {
    this.initializeMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['plantData'] && this.plantData) {
      this.groupPlantsByCoordinates();
      this.updateMapWithNewData();
    }
  }

  groupPlantsByCoordinates(): void {
    if (this.plantData) {
      this.plantGroupedByCoordinates = this.plantData
        .filter((plant) => plant.isActive)
        .reduce((acc, plant) => {
          const latitude = Number(plant.latitude).toFixed(4);
          const longitude = Number(plant.longitude).toFixed(4);
          const key = `${latitude},${longitude}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(plant);
          return acc;
        }, {} as { [key: string]: PlantData[] });
    }
  }

  updateMapWithNewData(): void {
    if (this.plantData && Array.isArray(this.plantData)) {
      const plantFeatures = this.plantData
        .filter((plant) => plant.isActive)
        .map((plant) => {
          const location = fromLonLat([
            parseFloat(plant.longitude),
            parseFloat(plant.latitude),
          ]);
          return new Feature({
            geometry: new Point(location),
            name: plant.name,
            id: plant.id,
            cityId: plant.cityId,
          });
        });

      const vectorSource = new VectorSource({
        features: plantFeatures,
      });

      if (this.map) {
        const vectorLayer = this.map
          .getLayers()
          .getArray()
          .find((layer) => layer instanceof VectorLayer) as any;

        if (vectorLayer) {
          vectorLayer.setSource(vectorSource);
        } else {
          console.error('Vector layer not found.');
        }
      }
    }
  }

  ngAfterViewInit(): void {
    const tooltipElement = document.getElementById('tooltip')!;
    this.tooltip = new Overlay({
      element: tooltipElement,
      offset: [0, -52],
      positioning: 'bottom-center',
    });
    this.map.addOverlay(this.tooltip);
    const mapElement = this.map.getTargetElement();
    this.map.on('pointermove', (event: { pixel: any; coordinate: any }) => {
      const features = this.map.getFeaturesAtPixel(event.pixel);
      if (features.length > 0) {
        const feature = features[0];
        const geometry = feature.getGeometry();
        if (geometry instanceof Point) {
          const coordinate = geometry.getCoordinates();
          const lonLat = toLonLat(coordinate);
          const key = `${lonLat[1].toFixed(4)},${lonLat[0].toFixed(4)}`;
          const plantsAtLocation = this.plantGroupedByCoordinates[key];
          if (plantsAtLocation) {
            const names = plantsAtLocation.map((p) => p.name).join(', ');
            tooltipElement.innerHTML = `${names}`;
            this.tooltip.setPosition(coordinate);
            tooltipElement.classList.add('ol-tooltip-visible');
          }
        }
      } else {
        tooltipElement.classList.remove('ol-tooltip-visible');
        this.tooltip.setPosition(undefined);
      }
      const hasFeature = this.map.hasFeatureAtPixel(event.pixel);
      mapElement.style.cursor = hasFeature ? 'pointer' : '';
    });

    setTimeout(() => {
      const zoomInButton = document.querySelector('.ol-zoom-in');
      const zoomOutButton = document.querySelector('.ol-zoom-out');

      if (zoomInButton) {
        const zoomInWrapper = document.createElement('div');
        zoomInWrapper.classList.add('custom-zoom-container');
        zoomInButton.parentNode?.insertBefore(zoomInWrapper, zoomInButton);
        zoomInWrapper.appendChild(zoomInButton);
      }

      if (zoomOutButton) {
        const zoomOutWrapper = document.createElement('div');
        zoomOutWrapper.classList.add('custom-zoom-container');
        zoomOutButton.parentNode?.insertBefore(zoomOutWrapper, zoomOutButton);
        zoomOutWrapper.appendChild(zoomOutButton);
      }
    }, 0);
  }

  checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
  }

  @HostListener('window:resize')
  onResize() {
    this.checkScreenSize();
  }
}
