import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { PlantData } from '../../interfaces/plant.interface';

@Component({
  selector: 'app-plant-location-card',
  standalone: true,
  imports: [ContextMenuModule, CommonModule],
  templateUrl: './plant-location-card.component.html',
  styleUrl: './plant-location-card.component.scss',
})
export class PlantLocationCardComponent {
  @Input() plantData!: PlantData[];
  @Input() actionItems!: MenuItem[] | ((plantData: PlantData) => MenuItem[]);
  @Input() actionGenerator!: (rowData: any) => MenuItem[];

  @ViewChild(ContextMenu) contextMenu!: ContextMenu;

  onActionsClick(event: MouseEvent, data: any) {
    const actions =
      typeof this.actionItems === 'function'
        ? this.actionItems(data)
        : this.actionItems;
    this.contextMenu.model = actions;
    this.contextMenu.show(event);
  }
}
