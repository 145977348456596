import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { Subscription } from 'rxjs';
import { API_END_POINTS } from '../../../constants/constant';
import { LineData } from '../../../interfaces/line.interface';
import { FactoryData, PlantData } from '../../../interfaces/plant.interface';
import { HeaderService } from '../../../services/header.service';
import { HttpService } from '../../../services/http.service';
import { ButtonComponent } from '../../../shared/button/button.component';

@Component({
  selector: 'app-select-location-modal',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ButtonComponent,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    MultiSelectModule,
  ],
  templateUrl: './select-location-modal.component.html',
  styleUrl: './select-location-modal.component.scss',
})
export class SelectLocationModalComponent {
  plantId!: number;
  cityId!: number;
  plantData: any;
  isSubmitted = false;
  locationForm: FormGroup;
  linesData!: LineData[];
  filteredLines!: LineData[];
  factoryData!: FactoryData[];
  lineSubscription?: Subscription;
  factorySubscription?: Subscription;
  plantName: string = 'Neemrana, Rajasthan';
  selectedLines: any;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ref: DynamicDialogRef,
    private httpService: HttpService,
    public config: DynamicDialogConfig,
    private headerService: HeaderService
  ) {
    this.locationForm = this.fb.group({
      plant: [null, Validators.required],
      factory: [null],
      line: [null],
    });
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.plantName = this.config.data.name;
      this.plantId = this.config.data.plantId;
      this.cityId = this.config.data.cityId;
      if (this.cityId) this.getPlantData();
    }
    this.factorySubscription = this.locationForm
      .get('factory')
      ?.valueChanges.subscribe((selectedFactory) => {
        this.filterLines(selectedFactory);
      });
    this.lineSubscription = this.locationForm
      .get('line')
      ?.valueChanges.subscribe((selectedLine) => {
        this.selectFactoryBasedOnLine(selectedLine);
      });

    this.subscriptions.add(
      this.headerService.lineIds.subscribe((lineIds: string[] | null) => {
        if (lineIds) {
          this.selectedLines = lineIds;
          localStorage.setItem(
            'selectedLines',
            JSON.stringify(this.selectedLines)
          );
        }
      })
    );
  }

  filterLines(selectedFactory: FactoryData) {
    if (selectedFactory) {
      this.filteredLines = this.linesData?.filter(
        (line) => line.factoryId === selectedFactory?.id
      );
    } else {
      this.filteredLines = this.linesData;
    }
  }

  selectFactoryBasedOnLine(selectedLine: LineData) {
    if (selectedLine) {
      const selectedFactory = this.factoryData?.find(
        (factory) => factory.id === selectedLine.factoryId
      );
      if (selectedFactory) {
        this.locationForm
          .get('factory')
          ?.setValue(selectedFactory, { emitEvent: false });
        this.filterLines(selectedFactory);
      }
    }
  }

  onPlantChange(event: { value: PlantData }) {
    if (event) {
      this.plantId = event.value.id;
      this.headerService.removeFactoryId();
      this.headerService.removeLineId();
      this.headerService.removeLineIds();
      this.locationForm.get('factory')?.setValue(null);
      this.locationForm.get('line')?.setValue(null);
      this.getFactoryData();
      this.getlineData();
    }
  }

  onFactoryChange(event: { value: FactoryData }) {
    const selectedFactory = event.value;
    this.filterLines(selectedFactory);
    this.locationForm.get('line')?.setValue(null);
  }

  getPlantData() {
    const url = `${API_END_POINTS.GET_ALL_PLANT_BY_CITY}${this.cityId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.plantData = response.data.filter((plant: any) => plant.isActive);
          if (this.plantData.length > 0) {
            this.locationForm
              .get('plant')
              ?.setValue(this.plantData[0], { emitEvent: false });
            this.plantId = this.plantData[0].id;
            this.getFactoryData();
            this.getlineData();
          }
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  getFactoryData() {
    const url = `${API_END_POINTS.GET_FACTORY_BY_PLANTID}${this.plantId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.factoryData = response.data;
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  getlineData() {
    const url = `${API_END_POINTS.GET_ALL_PLANT_LINES}${this.plantId}`;
    this.httpService
      .getRequest(url)
      .then((response) => {
        if (response) {
          this.linesData = response;
          this.filteredLines = this.linesData;
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onViewLines() {
    this.isSubmitted = true;
    if (this.locationForm.valid) {
      this.ref.close('confirmed');
      this.router.navigate(['/chart/daily-production']);
      localStorage.setItem('plantId', this.plantId.toString());
      if (this.locationForm.get('factory')?.value?.id) {
        localStorage.setItem(
          'factoryId',
          this.locationForm.get('factory')?.value.id
        );
      }
      // if (this.locationForm.get('line')?.value?.id) {
      //   localStorage.setItem('lineId', this.locationForm.get('line')?.value.id);
      // }
    }
  }

  get factoryControl() {
    return this.locationForm.get('factory');
  }

  get isSaveViewDisabled() {
    const factorySelected = !!this.locationForm.get('factory')?.value;
    const lineSelected = !!this.locationForm.get('line')?.value;
    return !(factorySelected || lineSelected);
  }

  onLineSelectionChange(event: any) {
    localStorage.setItem('selectedLines', JSON.stringify(this.selectedLines));
    this.headerService.setLineIds(this.selectedLines);
  }

  ngOnDestroy(): void {
    if (this.factorySubscription) {
      this.factorySubscription?.unsubscribe();
    }
    if (this.lineSubscription) this.lineSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }
}
